import React, { FC, useEffect, useRef, useState } from 'react';
import { Card, Cell, Div, FormItem } from '@vkontakte/vkui';
import { Icon20AddCircle, Icon24RemoveCircle } from '@vkontakte/icons';
import InputSearch from 'components/common/InputSearch';
import { IPerson } from 'components/Persons/types.persons';
import { ApiResponse } from 'lib/api/api';
import useFetch from 'lib/hooks/useFetch';
import { IEventPerson } from './types.events';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import useDebounce from 'lib/hooks/useDebounce';

interface IEventPersonsProps {
  newPersons: IEventPerson[];
  eventId: string | undefined;
}

const EventPersons: FC<IEventPersonsProps> = ({ newPersons, eventId }) => {
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [personSearchQuery, setPersonSearchQuery] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { fetchData: patchData } = useFetch();
  const debouncedSearchQuery = useDebounce(personSearchQuery, 500);
  const {
    data: personsData,
    loading: loadingPersons,
    fetchData: fetchPersons,
  } = useFetch<ApiResponse<IPerson[]>>();

  const fetchPersonsFunction = (query: string) => {
    fetchPersons({
      path: `/person/persons/?q=${query}`,
      method: 'get',
    });
  };

  const handleSearchPerson = () => {
    fetchPersonsFunction(personSearchQuery);
    setIsModalOpen(true);
  };

  const handleAddPerson = async (id: string) => {
    const idsArray = newPersons.map((item) => item.id);

    if (idsArray.includes(id)) {
      setSnackbarContent({
        type: 'error',
        message: 'Персона уже добавлена',
      });
      return;
    }

    try {
      await patchData({
        path: `/event/events/${eventId}/`,
        method: 'patch',
        body: { persons: [...idsArray, id] },
        onSuccessMessage: 'Персона добавлена',
      });

      navigate(0);
    } catch (error) {
      setSnackbarContent({
        type: 'error',
        message: 'Ошибка при добавлении персоны',
      });
    }
  };

  const handleRemovePerson = async (id: string) => {
    const idsArray = newPersons.map((item) => item.id);

    try {
      await patchData({
        path: `/event/events/${eventId}/`,
        method: 'patch',
        body: { persons: [...idsArray.filter((item) => item !== id)] },
        onSuccessMessage: 'Персона удалена',
      });

      navigate(0);
    } catch (error) {
      setSnackbarContent({
        type: 'error',
        message: 'Ошибка при удалении персоны',
      });
    }
  };

  useEffect(() => {
    if (debouncedSearchQuery) {
      fetchPersonsFunction(debouncedSearchQuery);
      setIsModalOpen(true);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (!loadingPersons && personsData?.results) {
      setPersons(personsData.results);
    }
  }, [loadingPersons]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('input')
      ) {
        setIsModalOpen(false);
        setPersonSearchQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="h-96">
      <FormItem>
        <InputSearch
          onSubmit={handleSearchPerson}
          value={personSearchQuery}
          onChange={(e) => setPersonSearchQuery(e.target.value)}
          placeholder="Добавить персону"
        />
      </FormItem>
      {isModalOpen && !loadingPersons && (
        <div ref={modalRef} className="absolute top-16 left-2 w-fit z-10">
          {persons.length > 0 ? (
            <FormItem>
              <Card mode="shadow">
                <ul>
                  {persons.map((person) => (
                    <li
                      key={person.id}
                      className="p-2 hover:text-gray-400 cursor-pointer flex gap-2 items-center"
                      onClick={() => {
                        handleAddPerson(person.id);
                        setIsModalOpen(false);
                        setPersonSearchQuery('');
                      }}
                    >
                      <Icon20AddCircle width={14} color={'#5181B8'} />
                      {person.full_name}
                    </li>
                  ))}
                </ul>
              </Card>
            </FormItem>
          ) : (
            <FormItem>
              <Card mode="shadow">
                <Div>По вашему запросу ничего не найдено</Div>
              </Card>
            </FormItem>
          )}
        </div>
      )}
      <FormItem>
        <ul className="flex gap-4">
          {newPersons.map(({ id, full_name }) => {
            return (
              <li key={id}>
                <Cell
                  className="group bg-color-card-grey text-color-steel-gray-500 rounded-md min-w-fit"
                  after={
                    <Icon24RemoveCircle
                      className="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -right-5 -top-5 transform -translate-y-1/2"
                      width={28}
                      height={28}
                      color={'#e64646'}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleRemovePerson(id);
                      }}
                    />
                  }
                >
                  {full_name}
                </Cell>
              </li>
            );
          })}
        </ul>
      </FormItem>
    </div>
  );
};

export default EventPersons;
